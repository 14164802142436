@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background-color: rgb(18, 18, 18);
  color: rgba(255, 255, 255, 0.87);
  font-size: 1rem;
  font-family: Calibbri, Arial, sans-serif;
  line-height: 1.5rem;
  overflow-x: hidden;

  font-weight: 400;
  text-rendering: optimizelegibility;
}
.clip {
  position: fixed;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.app {
  margin: 50%;
  width: 100vw;
  height: 100vh;
}

.hero {
  bottom: 0;
}
html {
  scroll-behavior: smooth;
}
.dev-ops {
  color: #fbbc05;
}
.h1 {
  color: #ebf2fa;
  /* background-image: linear-gradient(
    43deg,
    #fea128 0%,
    #1fecab 46%,
    #2861f6 100%
  ); */
}

h3 {
  font-size: 1.6rem !important;
  font-weight: bold;
  background-color: #74ebd5;
  background-image: -webkit-linear-gradient(90deg, #74ebd5 0%, #85ecec 100%);
  background-image: -moz-linear-gradient(90deg, #74ebd5 0%, #85ecec 100%);
  background-image: -o-linear-gradient(90deg, #74ebd5 0%, #85ecec 100%);
  background-image: linear-gradient(90deg, #74ebd5 0%, #85ecec 100%);

  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
p {
  font-size: 1.22rem;
  line-height: 2rem;
}

h2 {
  font-size: 1.7rem !important;
  font-weight: bold;
  line-height: 2.4rem;
  padding-bottom: 0.5rem;
  font-weight: bold;
  background-color: #fad961;
  background-image: -webkit-linear-gradient(90deg, #fad961 0%, #f7c71c 100%);
  background-image: -moz-linear-gradient(90deg, #fad961 0%, #f7c71c 100%);
  background-image: -o-linear-gradient(90deg, #fad961 0%, #f7c71c 100%);
  background-image: linear-gradient(90deg, #fad961 0%, #f7c71c 100%);

  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.patterns {
  background-image: url("./images/bg-effects.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

img {
  width: 100%;
  height: 100%;
}

input {
  height: 40px;
}

.gradient:focus {
  border: 1px solid transparent;
  background-image: linear-gradient(#141414, #141414),
    linear-gradient(43deg, #a162e8 0%, #f7ce68 46%, #85ffbd 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

input,
textarea {
  background: #121212c5;
  border-radius: 0.3rem;
  border: 1px solid transparent;
}

*:focus:not(.focus-visible) {
  outline: none;
}

/* Loader */

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
#tsparticles > .tsparticles-canvas-el {
  animation: zoomFitIn 1000ms both;
}
@keyframes zoomFitIn {
  from {
    opacity: 0;
    filter: blur(5px);
    transform-origin: 50% 50%;
    transform: scale3d(5, 5, 5);
  }

  75% {
    opacity: 1;
    filter: blur(0px);
    transform-origin: 50% 50%;
    transform: scale3d(1.25, 1.25, 1.25);
  }

  to {
    transform-origin: 50% 50%;
    transform: none;
  }
}
#tsparticles > .tsparticles-canvas-el {
  animation: puffBigIn 1200ms both;
}
@keyframes puffBigIn {
  0% {
    transform: scale3d(10, 10, 10);
    opacity: 0;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}
div > .hero > .herp {
  animation: zoomIn 1500ms both;
}
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}
#root > .container > .nav {
  animation: slideInDown 1200ms 1500ms both;
}
@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
